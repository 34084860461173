// ** MUI
import Box from '@mui/material/Box';

// ** Assets
import LogoHotels from 'src/assets/svg/logo-hotels.svg';
import LogoBuildings from 'src/assets/svg/logo-buildings.svg';
import LogoCars from 'src/assets/svg/logo-cars.svg';

const logoImageSrc = (project) => {
  switch (project) {
    case 'buildings':
      return LogoBuildings;
    case 'cars':
      return LogoCars;
    default:
      return LogoHotels;
  }
};

const Logo = ({ project, size = 100 }) => {
  return (
    <Box
      display={'inline-block'}
      component={'img'}
      alt={''}
      src={logoImageSrc(project)}
      sx={{
        width: size,
      }}
    />
  );
};

export default Logo;
