import profileActions from './actions';

import { v4 as uuidv4 } from 'uuid';
import { jwtDecode } from 'jwt-decode';

import { getData, setData, removeData } from '../../helpers/storage';
import eventBus from '../../helpers/eventBus';

import profileAPI from '../../api/profile';
import staysAPI from '../../api/stays';

const getConfig = () => async (dispatch) => {
  dispatch(profileActions.togglePendingConfig(true));
  dispatch(
    profileActions.setErrorConfig({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await profileAPI.getConfig();
    dispatch(profileActions.setConfig(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        profileActions.setErrorConfig({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        profileActions.setErrorConfig({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(profileActions.togglePendingConfig(false));
  }
};

const getStayInfo = () => async (dispatch) => {
  dispatch(profileActions.togglePendingStayInfo(true));
  dispatch(
    profileActions.setErrorStayInfo({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await staysAPI.getStays({ page: 1 });
    const activeStayId = getData('activeStayId');

    let activeStay;
    if (activeStayId) {
      activeStay = data.results.find((stay) => stay.id === activeStayId) || null;
    } else if (data.results?.[0]) {
      activeStay = data.results[0];
    }

    if (activeStay?.id) {
      await dispatch(setStayInfo(activeStay));
    }

    return activeStay;
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        profileActions.setErrorStayInfo({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        profileActions.setErrorStayInfo({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(profileActions.togglePendingStayInfo(false));
  }
};

const setStayInfo = (info) => async (dispatch) => {
  setData('activeStayId', info.id);

  dispatch(profileActions.setStayInfo(info));
  dispatch(getMenus());
  return info;
};

const getUniqueId = (force) => async (dispatch) => {
  dispatch(profileActions.togglePendingUniqueId(true));
  dispatch(
    profileActions.setErrorUniqueId({
      status: null,
      text: null,
    }),
  );

  try {
    let info = getData('uniqueId');
    if (!info || force) {
      const uniqueId = uuidv4();
      const { data } = await profileAPI.getToken({
        id: uniqueId,
      });
      let decoded = data.token && jwtDecode(data.token);
      info = {
        id: uniqueId,
        token: data.token,
        user_id: decoded?.user_id,
      };
    }
    setData('uniqueId', info);
    dispatch(profileActions.setUniqueId(info));

    return info;
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        profileActions.setErrorUniqueId({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        profileActions.setErrorUniqueId({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
    return response;
  } finally {
    dispatch(profileActions.togglePendingUniqueId(false));
  }
};

const setUniqueId = (info) => async (dispatch) => {
  setData('uniqueId', info);

  dispatch(profileActions.setUniqueId(info));
};

const getMenus = () => async (dispatch, getState) => {
  const { profile } = getState();
  const { stayInfo } = profile.data;

  if (!stayInfo) return;

  dispatch(profileActions.togglePendingMenus(true));
  dispatch(
    profileActions.setErrorMenus({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await profileAPI.getMenus({
      hotel: stayInfo?.hotel?.id,
    });
    dispatch(profileActions.setMenus(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        profileActions.setErrorMenus({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        profileActions.setErrorMenus({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(profileActions.togglePendingMenus(false));
  }
};

const resetMenus = () => async (dispatch) => {
  dispatch(profileActions.resetMenus());
};

const submitStayByCode =
  ({ code, guestName, roomNumber, start, end }) =>
  async (dispatch) => {
    try {
      const response = await profileAPI.submitStay({
        hotelCode: code,
        ...(guestName
          ? {
              guestName: guestName,
            }
          : {}),
        ...(roomNumber
          ? {
              roomNumber: roomNumber,
            }
          : {}),
        ...(start
          ? {
              start: start,
            }
          : {}),
        ...(end
          ? {
              end: end,
            }
          : {}),
      });

      await dispatch(setStayInfo(response.data));

      return response;
    } catch ({ response }) {
      return response;
    }
  };

const submitStayById =
  ({ id, guestName, roomNumber, start, end }) =>
  async (dispatch) => {
    try {
      const response = await profileAPI.submitStay({
        hotelId: id,
        ...(guestName
          ? {
              guestName: guestName,
            }
          : {}),
        ...(roomNumber
          ? {
              roomNumber: roomNumber,
            }
          : {}),
        ...(start
          ? {
              start: start,
            }
          : {}),
        ...(end
          ? {
              end: end,
            }
          : {}),
      });

      await dispatch(setStayInfo(response.data));

      return response;
    } catch ({ response }) {
      return response;
    }
  };

const setPassword =
  ({ newPassword, currentPassword }) =>
  async (dispatch) => {
    dispatch(profileActions.togglePendingLogin(true));
    dispatch(
      profileActions.setErrorLogin({
        status: null,
        text: null,
      }),
    );

    try {
      const response = await profileAPI.setPassword({
        newPassword: newPassword,
        currentPassword: currentPassword,
      });
      return response;
    } catch ({ response }) {
      if (typeof response === 'undefined') {
        dispatch(
          profileActions.setErrorLogin({
            status: '0',
            text: null,
          }),
        );
      } else {
        dispatch(
          profileActions.setErrorLogin({
            status: response.status,
            text: response.data && response.data.detail ? response.data.detail : response.statusText,
          }),
        );
      }
      return response;
    } finally {
      dispatch(profileActions.togglePendingLogin(false));
    }
  };

const setEmail =
  ({ newEmail, currentPassword }) =>
  async (dispatch) => {
    dispatch(profileActions.togglePendingLogin(true));
    dispatch(
      profileActions.setErrorLogin({
        status: null,
        text: null,
      }),
    );

    try {
      const response = await profileAPI.setEmail({
        newEmail: newEmail,
        currentPassword: currentPassword,
      });
      return response;
    } catch ({ response }) {
      if (typeof response === 'undefined') {
        dispatch(
          profileActions.setErrorLogin({
            status: '0',
            text: null,
          }),
        );
      } else {
        dispatch(
          profileActions.setErrorLogin({
            status: response.status,
            text: response.data && response.data.detail ? response.data.detail : response.statusText,
          }),
        );
      }
      return response;
    } finally {
      dispatch(profileActions.togglePendingLogin(false));
    }
  };

const resetPassword =
  ({ email }) =>
  async (dispatch) => {
    dispatch(profileActions.togglePendingLogin(true));
    dispatch(
      profileActions.setErrorLogin({
        status: null,
        text: null,
      }),
    );

    try {
      const response = await profileAPI.resetPassword({
        email: email,
      });
      return response;
    } catch ({ response }) {
      if (typeof response === 'undefined') {
        dispatch(
          profileActions.setErrorLogin({
            status: '0',
            text: null,
          }),
        );
      } else {
        dispatch(
          profileActions.setErrorLogin({
            status: response.status,
            text: response.data && response.data.detail ? response.data.detail : response.statusText,
          }),
        );
      }
      return response;
    } finally {
      dispatch(profileActions.togglePendingLogin(false));
    }
  };

const login =
  ({ email, password }) =>
  async (dispatch) => {
    eventBus.publish('ws:disconnect');

    dispatch(profileActions.togglePendingLogin(true));
    dispatch(
      profileActions.setErrorLogin({
        status: null,
        text: null,
      }),
    );

    try {
      const response = await profileAPI.login({
        email: email,
        password: password,
      });
      let loginData = getData('loginInfo');
      loginData = {
        ...loginData,
        ...response.data,
      };
      setData('loginInfo', loginData);
      dispatch(profileActions.setLogin(loginData));
      dispatch(getProfile());

      return response;
    } catch ({ response }) {
      if (typeof response === 'undefined') {
        dispatch(
          profileActions.setErrorLogin({
            status: '0',
            text: null,
          }),
        );
      } else {
        dispatch(
          profileActions.setErrorLogin({
            status: response.status,
            text: response.data && response.data.detail ? response.data.detail : response.statusText,
          }),
        );
      }
      return response;
    } finally {
      dispatch(profileActions.togglePendingLogin(false));
    }
  };

const loginBySocial = (provider, code, redirectUri) => async (dispatch) => {
  eventBus.publish('ws:disconnect');

  dispatch(profileActions.togglePendingLogin(true));
  dispatch(
    profileActions.setErrorLogin({
      status: null,
      text: null,
    }),
  );

  try {
    const response = await profileAPI.loginBySocial(provider, code, redirectUri);
    const loginData = {
      access: response.data.token,
      refresh: response.data.refresh,
    };
    setData('loginInfo', loginData);
    dispatch(profileActions.setLogin(loginData));
    dispatch(getProfile());

    return response;
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        profileActions.setErrorLogin({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        profileActions.setErrorLogin({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(profileActions.togglePendingLogin(false));
  }
};

const register =
  ({ email, password, phoneNumber, firstName, lastName }) =>
  async (dispatch) => {
    dispatch(profileActions.togglePendingRegister(true));
    dispatch(
      profileActions.setErrorRegister({
        status: null,
        text: null,
      }),
    );

    try {
      const response = await profileAPI.register({
        email: email,
        password: password,
        phoneNumber: phoneNumber,
        firstName: firstName,
        lastName: lastName,
      });

      return response;
    } catch ({ response }) {
      if (typeof response === 'undefined') {
        dispatch(
          profileActions.setErrorRegister({
            status: '0',
            text: null,
          }),
        );
      } else {
        dispatch(
          profileActions.setErrorRegister({
            status: response.status,
            text: response.data && response.data.detail ? response.data.detail : response.statusText,
          }),
        );
      }
      return response;
    } finally {
      dispatch(profileActions.togglePendingRegister(false));
    }
  };

const refresh = (token) => async (dispatch) => {
  try {
    const response = await profileAPI.refresh(token);
    let loginData = getData('loginInfo');
    loginData = {
      ...loginData,
      ...response.data,
    };
    setData('loginInfo', loginData);
    dispatch(profileActions.setLogin(loginData));
    dispatch(getProfile());

    return loginData;
  } catch ({ response }) {
    dispatch(logout());
  }
};

const getLoginInfo = () => async (dispatch) => {
  try {
    const loginInfo = getData('loginInfo');
    if (loginInfo) {
      dispatch(profileActions.setLogin(loginInfo));
      return dispatch(getProfile());
    }
  } catch ({ response }) {
    //
  }
};

const getProfile = () => async (dispatch, getState) => {
  const { profile } = getState();
  const { loginInfo } = profile.data;

  if (!loginInfo?.access) return;

  dispatch(profileActions.togglePendingProfile(true));
  dispatch(
    profileActions.setErrorProfile({
      status: null,
      text: null,
    }),
  );

  try {
    const response = await profileAPI.getProfile();
    dispatch(profileActions.setProfile(response.data));

    return Promise.resolve(response);
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        profileActions.setErrorProfile({
          status: '0',
          text: null,
        }),
      );
    } else if (response.status >= 500) {
      return Promise.reject(response);
    } else {
      dispatch(
        profileActions.setErrorProfile({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );

      dispatch(logout());
    }
  } finally {
    dispatch(profileActions.togglePendingProfile(false));
  }
};

const editProfile = (values) => async (dispatch, getState) => {
  const { profile } = getState();
  const { loginInfo } = profile.data;

  if (!loginInfo?.access) return;

  dispatch(profileActions.togglePendingProfile(true));
  dispatch(
    profileActions.setErrorProfile({
      status: null,
      text: null,
    }),
  );

  try {
    const response = await profileAPI.editProfile(values);
    dispatch(profileActions.setProfile(response.data));

    return response;
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        profileActions.setErrorProfile({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        profileActions.setErrorProfile({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
    return response;
  } finally {
    dispatch(profileActions.togglePendingProfile(false));
  }
};

const logout = () => async (dispatch) => {
  removeData('loginInfo');
  removeData('activeStayId');

  eventBus.publish('ws:disconnect');

  await dispatch(profileActions.logout());
};

const setLoader = (value) => async (dispatch) => {
  dispatch(profileActions.setLoader(value));
};

const registerFcmDevice = (values) => async () => {
  try {
    const response = await profileAPI.registerFcmDevice(values);

    return response;
  } catch ({ response }) {
    return response;
  }
};

const activate = async (values) => {
  try {
    const response = await profileAPI.activate(values);

    return response;
  } catch ({ response }) {
    return response;
  }
};

const resetPasswordConfirm = async (values) => {
  try {
    const response = await profileAPI.resetPasswordConfirm(values);

    return response;
  } catch ({ response }) {
    return response;
  }
};

const exportedObject = {
  getConfig,
  getStayInfo,
  setStayInfo,
  getUniqueId,
  setUniqueId,
  getMenus,
  resetMenus,
  submitStayByCode,
  submitStayById,
  login,
  loginBySocial,
  register,
  setPassword,
  resetPassword,
  setEmail,
  refresh,
  getLoginInfo,
  getProfile,
  editProfile,
  logout,
  setLoader,
  registerFcmDevice,
  activate,
  resetPasswordConfirm,
};

export default exportedObject;
